import { template as template_9503e01ba8aa4129bf4f9ab7f995d759 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9503e01ba8aa4129bf4f9ab7f995d759(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
