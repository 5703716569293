import { template as template_39624e60eb964601a2a6698f3c845e9c } from "@ember/template-compiler";
const WelcomeHeader = template_39624e60eb964601a2a6698f3c845e9c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
